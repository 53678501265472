import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import * as React from "react";
import Slider from "react-slick";

import HomeDesignElement from "../../../../assets/HomeDesignElement.svg";
import {MutatedArrow} from "../../../commons/SliderArrow";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const MasonTrustSection = ({cmsRef, hideDesignElement = false}) => {
  const settingslogo = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 2,
    touchMove: true,
    nextArrow: <MutatedArrow />,
    prevArrow: <MutatedArrow prev />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className={styler.get("companies", ["full-bleed", "layouted"])}>
      {!hideDesignElement && (
        <HomeDesignElement className={styler.get("rightDesignElement")} />
      )}
      <h2 className={styler.get("", "light")}>
        Trusted by <b>10,000+</b> global eCommerce brands & teams
      </h2>
      <div className={styler.get("csliders")}>
        <Slider {...settingslogo}>
          {cmsRef.logos.map(({logo, name, alt}) => (
            <div className={styler.get("compWrap")} key={name}>
              <div className={styler.get("comp")}>
                <GatsbyImage
                  image={logo?.asset?.gatsbyImageData}
                  alt={alt || name}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default MasonTrustSection;
