import {StyleExtractor} from "@kubric/utils";
import React, {useEffect, useState} from "react";

import CTA from "../CTA";
import * as styles from "./styles.module.scss";

const softs = [
  {
    amazon: "https://media.kubric.io/api/videos-kubric/image_png_205520185.png",
  },
  {
    sketch:
      "https://media.kubric.io/api/assetlib/ac0babf7-8f1b-4b50-9daf-2bc50eb9e3ec.png",
  },
  {
    pexels:
      "https://media.kubric.io/api/assetlib/456230cf-f7fc-4175-afdf-1560fc57f9e4.png",
  },
  {
    intercom:
      "https://media.kubric.io/api/videos-kubric/image_png_1185357657.png",
  },
  {
    slack:
      "https://media.kubric.io/api/assetlib/c8319ecb-b86b-44ae-b2e2-14c3e04460c6.png",
  },
  {
    shopify:
      "https://media.kubric.io/api/assetlib/2d6df61b-f2b5-4ee3-b69c-e5ac9083301b.png",
  },
  {
    mailchimp:
      "https://media.kubric.io/api/assetlib/f2b434ba-f296-499a-b52e-83a3a973963c.png",
  },
  {
    facebook:
      "https://media.kubric.io/api/videos-kubric/image_png1455593231.png",
  },
  {
    photoshop:
      "https://media.kubric.io/api/videos-kubric/image_png_594710724.png",
  },
  {
    google:
      "https://media.kubric.io/api/videos-kubric/image_png_1421672417.png",
  },
  {
    figma:
      "https://media.kubric.io/api/assetlib/5a07b46b-2b79-45e1-9891-f6660813bea8.png",
  },
  {
    unsplash:
      "https://media.kubric.io/api/assetlib/c72e58e3-7d9c-4429-93db-aa5f88c01d21.png",
  },
];

const integrations = [
  {
    label: "Growth Lead",
    softs: ["shopify", "figma", "facebook", "mailchimp", "amazon"],
    color: "#2C60AD",
  },
  {
    label: "Product Manager",
    softs: ["photoshop", "google", "sketch", "slack"],
    color: "#8A3063",
  },
  {
    label: "Marketer",
    softs: ["photoshop", "google", "sketch", "slack", "shopify"],
    color: "#FE6960",
  },
  {
    label: "Designer",
    softs: ["photoshop", "figma", "sketch", "pexels", "unsplash"],
    color: "#ED9D00",
  },
  {
    label: "Developer",
    softs: ["photoshop", "google", "sketch", "amazon", "figma"],
    color: "#02152D",
  },
];

const Integrations = ({
  theme = {},
  roleIndex = 0,
  showCTA = false,
  usedInMason = false,
}) => {
  const [currentIndex, setIndex] = useState(
    roleIndex < 0 || roleIndex > integrations.length - 1 ? 0 : roleIndex
  );
  const styler = new StyleExtractor(styles, theme);
  useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      setIndex((prev) => {
        if (prev === 4) {
          return 0;
        }
        return prev + 1;
      });
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [currentIndex]);
  const selectedRole = integrations[currentIndex];
  const H = usedInMason ? "h2" : "h1";
  return (
    <section className={styler.get("container", "full-bleed")}>
      <div className={styler.get("widthFixer")}>
        <div className={styler.get("heading")}>
          <H>Works for you, Works for your team</H>
          <h3 className={styler.get("subHeading")}>
            Mason connects with your all your favourite tools. Whether you're a
            🚀growth manager, 🧑‍🔧 product manager, 🗣️marketer, 🎨 designer or
            🧑‍💻developer - connect your fav tools with Mason to get into a
            flow state.
          </h3>
          {showCTA && (
            <CTA
              data={{
                cta: "Get started with your workflow now",
                href: "https://app.getmason.io/login",
                bgColor: "black",
                borderColor: "black",
                textColor: "white",
              }}
              theme={{
                cta: styles.cta,
              }}
            />
          )}
        </div>
        <div className={styler.get("roles")}>
          {integrations.map(({label, color}, index) => {
            const isSelected = selectedRole.label === label;
            return (
              <div
                className={styler.get(["role", isSelected && "activeRole"])}
                onClick={() => setIndex(index)}
                style={{
                  background: isSelected ? color : "var(--gray)",
                }}
              >
                <p>{label}</p>
              </div>
            );
          })}
        </div>
        <div className={styler.get("integrationGrid")}>
          <div
            className={styler.get(["mason", "activeSoft"])}
            style={{color: selectedRole.color}}
          >
            mason
          </div>
          {softs.map((s) => (
            <div
              className={styler.get([
                "software",
                selectedRole.softs.includes(Object.keys(s)[0]) && "activeSoft",
              ])}
              style={{
                backgroundImage: `url(${s[Object.keys(s)[0]]})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                color: selectedRole.color,
                backgroundBlendMode: "multiply",
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Integrations;
