import React from "react";

import Arrow from "../../assets/Arrow.svg";

export const MutatedArrow = ({className = "", onClick, prev = false}) => {
  return (
    <Arrow
      className={`${prev ? "preArrow" : "nxtArrow"} ${className} sliderArrow`}
      onClick={onClick}
    />
  );
};
