// extracted by mini-css-extract-plugin
export var activeRole = "styles-module--activeRole--33ZK0";
export var activeSoft = "styles-module--activeSoft--2PCYT";
export var container = "styles-module--container--1S5Cd";
export var cta = "styles-module--cta--1F0Va";
export var heading = "styles-module--heading--3Nrjj";
export var integrationGrid = "styles-module--integrationGrid--RgEOj";
export var mason = "styles-module--mason--2Wxin";
export var role = "styles-module--role--1P7To";
export var roles = "styles-module--roles--3AmdK";
export var software = "styles-module--software--3Z_tR";
export var subHeading = "styles-module--subHeading--2mnzo";
export var widthFixer = "styles-module--widthFixer--2AM7q";