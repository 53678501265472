import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";

import "./subscribe.css";

// type can be white/yellow
const Subscribe = ({
  buttonText,
  id = "",
  ctaLink = "https://app.getmason.io/login",
  type = "white",
  fontSize = "1.25rem",
}) => {
  return (
    <div className='actionWrap'>
      <OutboundLink
        className={`pbuttonWrap ${type}`}
        target='_blank'
        id={id}
        href={ctaLink}
        rel='noopener noreferrer'
      >
        <p className='medium' style={{fontSize}}>
          {buttonText}
        </p>
      </OutboundLink>
    </div>
  );
};

export default Subscribe;
