import {graphql} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import HomeDesignElement from "../../assets/HomeDesignElement.svg";
import threeVid from "../../assets/animThree.mp4";
import Deco01 from "../../assets/vect01.svg";
import Deco03 from "../../assets/vect03.svg";
import Layout from "../../components/Layout";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import EmailCTA from "../../components/commons/EmailCTA";
import Integrations from "../../components/commons/Integrations";
import SanityRichText from "../../components/commons/SanityRichText";
import Reviews from "../../components/pages/home/Reviews";
import Subscribe from "../../components/pages/home/Subscribe/Subscribe";
import MasonTrustSection from "../../components/pages/mason/MasonTrustSection";
import "../styles.scss";

const Home = ({data}) => {
  const cmsRef = data.allSanityHomepage.nodes[0];
  const seoData = data.allSanitySeoMeta.nodes[0];

  const {
    beforeHeading,
    middleHeading,
    afterHeading,
    cta,
    color: {hex: color} = {},
    _rawDescription,
    _rawBelowCtaText,
  } = cmsRef.hero[0];

  const sections = [
    cmsRef.buildSection,
    cmsRef.connectSection,
    cmsRef.deploySection,
  ];

  return (
    <Layout seoData={seoData}>
      <StructuredData
        type={StructuredDataType.PRODUCT}
        data={{
          key: "home",
        }}
      />
      <section className='hero full-bleed'>
        <div className='heroContainer'>
          <div className='left'>
            <h1 className='heroHeading mt-4 md:mt-0'>
              {!!beforeHeading && `${beforeHeading} `}
              <span style={{color}}>
                {!!middleHeading && `${middleHeading} `}
              </span>
              {!!afterHeading && afterHeading}
            </h1>
            <SanityRichText
              className='desc my-8 font-light'
              blocks={_rawDescription}
              renderContainerOnSingleChild
            />
            <EmailCTA
              data={{
                cta,
                bgColor: "var(--almost_black)",
                textColor: "white",
                action: "masonHomeCtaFn",
              }}
              id='get-mason-app'
              showLoading
            />
            <SanityRichText
              className='desc lower mt-8 font-light'
              blocks={_rawBelowCtaText}
              renderContainerOnSingleChild
            />
          </div>
          <div className='heroImageContainer'>
            <video className='heroImage' autoPlay muted loop playsInline>
              <source
                src='https://media.kubric.io/api/assetlib/2f6f7135-74cd-48e5-9f4c-41485d82967f.mp4'
                type='video/mp4'
              />
              <source
                src='https://media.kubric.io/api/assetlib/8fe66ce5-9420-44e4-be94-308fd92b3b27.webm'
                type='video/webm'
              />
            </video>
          </div>
        </div>
      </section>
      <MasonTrustSection cmsRef={cmsRef} />
      {/* <div className='layouted full-bleed mainSections'> */}
      {sections.map((section, index) => (
        <section
          className={`section relative layouted full-bleed section-${index}`}
          key={section.heading}
        >
          {index === 2 && <HomeDesignElement className='leftDesignElement' />}
          {index === 0 && (
            <h2 className='sectionsMainHeading'>Packed with Power Modes</h2>
          )}
          <div className='sectionContainer'>
            <div className='sectionContent'>
              <h2 className='sectionHeading'>{section.heading}</h2>
              <SanityRichText
                renderContainerOnSingleChild
                blocks={section._rawSubtext}
                className='sectionSubTxt'
              />
              <div className='flex items-center gap-4 flex-wrap'>
                {section?.tags?.map((tag) => (
                  <p key={tag} className='sectionCta'>
                    {tag}
                  </p>
                ))}
              </div>
            </div>
            {section?.video?.asset?.url ? (
              <video autoPlay loop muted playsInline className='sectionImage'>
                <source
                  src='https://media.kubric.io/api/assetlib/be59369a-2dc0-4560-9280-f702eaa9263d.mp4'
                  type='video/mp4'
                />
                <source src={section?.video?.asset?.url} type='video/webm' />
              </video>
            ) : (
              <GatsbyImage
                className='sectionImage'
                objectFit='contain'
                objectPosition='center'
                imgClassName='sectionImageClass'
                image={section?.image?.asset?.gatsbyImageData}
                alt={section?.alt}
              />
            )}
          </div>
        </section>
      ))}
      {/* </div> */}
      <Integrations usedInMason />
      <section id='better' className='filler full-bleed'>
        <h2 className='newWay'>
          <span>{cmsRef.section4title}</span>
          <div className='deco d3'>
            <Deco03 />
          </div>
        </h2>
        <div className='row'>
          <div className='animBox'>
            <video autoPlay muted loop playsInline>
              <source src={threeVid} type='video/mp4' />
            </video>
          </div>
          <div className='content'>
            <h2 className='leftAlign'>{cmsRef.section4content.title}</h2>
            <p style={{margin: "1rem 0"}}>{cmsRef.section4content.desc}</p>
            <Subscribe
              style={{margin: "0"}}
              buttonText='Get Started for Free'
              id='get-mason-app'
            />
          </div>
        </div>
        <div className='deco d4'>
          <Deco01 />
          <br />
          <Deco01 />
          <br />
          <Deco01 />
        </div>
      </section>
      <section className='reviews full-bleed'>
        <div className='revs'>
          <h2 className='light'>Getting love from early adopters</h2>
          <div className='revCardBox'>
            <Reviews data={cmsRef.reviewsection} />
          </div>
        </div>
      </section>
      <h2 className='hook'>
        You have questions.
        <br />
        <OutboundLink href=''>We’re happy to answer them ➚</OutboundLink>
      </h2>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "mason"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityHomepage {
      nodes {
        reviewsection {
          company
          content
          desc
          imgUrl
          user
          color
          _key
        }
        herosectionsub
        section4content {
          desc
          title
        }
        logos {
          logo {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          name
        }
        section4title
        sectionLast {
          desc
          title
        }
        featsection {
          content
          label
          subtext
        }
        hero {
          afterHeading
          beforeHeading
          middleHeading
          cta
          _rawDescription
          _rawBelowCtaText
          color {
            hex
          }
        }
        connectSection {
          heading
          imagePosition
          _rawSubtext
          tags
          image {
            asset {
              gatsbyImageData
            }
          }
          alt
          video {
            asset {
              url
            }
          }
        }
        buildSection {
          heading
          imagePosition
          _rawSubtext
          tags
          image {
            asset {
              gatsbyImageData
            }
          }
          alt
        }
        deploySection {
          heading
          imagePosition
          _rawSubtext
          tags
          image {
            asset {
              gatsbyImageData
            }
          }
          alt
        }
      }
    }
  }
`;

export default Home;
