import React from "react";
import Slider from "react-slick";

import {MutatedArrow} from "../../../commons/SliderArrow";
import ReviewCard from "./ReviewCard";

export default ({data}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    nextArrow: <MutatedArrow />,
    prevArrow: <MutatedArrow prev />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {data.map((rev) => (
        <ReviewCard {...rev} />
      ))}
    </Slider>
  );
};
