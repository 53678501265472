import React from "react";

import "./reviewCard.css";

const ReviewCard = (data) => {
  const {imgUrl, user, color, desc, company, content} = data;
  return (
    <div className='revCard' style={{border: `4px solid ${color[0]}`}}>
      <div className='upper'>
        <div
          className='ppic'
          style={{
            background: `url(${imgUrl})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            border: `4px solid ${color[0]}`,
          }}
        />
        <h4 className='medium'>{user}</h4>
        <div className='descs'>
          <div className='smolCaption bold work' style={{background: color[0]}}>
            {desc}
          </div>
          <div className='caption'>{company}</div>
        </div>
      </div>
      <p className='revText'>{content}</p>
    </div>
  );
};

export default ReviewCard;
